class Config {
    constructor() {
        this.env = 'training';
        this.backend = 'https://api.training.mrm.bpce.audience-pro.com';
        this.kms = 'https://kms.mrm.bpce.audience-pro.com/';
        this.api = this.backend + '/api';
        this.notificationRefeshInterval = 20000; // ms
        this.threadRefeshInterval = 20000; // ms
    }
}

export default new Config();
